<template>
  <v-container>
    <v-card
      outlined
      rounded="lg"
    >
      <v-toolbar flat>
        <v-toolbar-title> {{ offer.title }} </v-toolbar-title>
        <v-spacer />
        <v-btn
          v-if="offer.active"
          color="warning"
          class="my-4 mx-3"
          elevation="0"
          outlined
          @click="updateOffer({active: false})"
        >
          {{ $t('disable') }}
        </v-btn>
        <v-btn
          v-else
          color="success"
          class="my-4 mx-3"
          elevation="0"
          outlined
          @click="updateOffer({active: true})"
        >
          {{ $t('enable') }}
        </v-btn>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn
                v-if="!processing && !offerFormValid"
                color="success"
                class="my-4"
                width="135px"
                elevation="0"
                disabled
              >
                {{ $t('save') }}
              </v-btn>
            </div>
          </template>
          {{ $t('missingRequiredValuesOrValuesAreIncorrect') }}
        </v-tooltip>
        <v-btn
          v-if="!processing && offerFormValid"
          color="success"
          class="my-4"
          width="135px"
          @click="triggerFormUpdateOffer"
          elevation="0"
        >
          {{ $t('save') }}
        </v-btn>
        <v-btn
          v-if="processing"
          color="success"
          disabled
          width="135px"
        >
          <v-progress-circular
            indeterminate
          />
          <v-icon />
        </v-btn>
      </v-toolbar>
    </v-card>
    <v-row>
      <v-col>
        <OfferForm 
          :value="offerToUpdate"
          :trigger-update="formUpdateOffer"
          @finishedUpdating="formUpdateOffer = false"
          @valid="setOfferFormValidity"
          v-on="$listeners"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
    
  <script>
  export default {
      name: 'OfferProfile',
      props: {
          offer: {
              type: Object,
              required: true,
              default: () => {}
          }
      }, 
      components: {
          'OfferForm': () => import('@/components/offers/OfferForm')
      },
      data() {
          return {
              formUpdateOffer: false,
              offerFormValid: false,
              processing: false
          }
      },
      computed: {
        offerToUpdate() {
          const offerToUpdate = JSON.parse(JSON.stringify(this.offer))

          if(!offerToUpdate.items || !Array.isArray(offerToUpdate.items)) {
            offerToUpdate.items = []
          }

          if(!offerToUpdate.conditions || !Array.isArray(offerToUpdate.conditions)) {
            offerToUpdate.items = []
          }
          
          return offerToUpdate
        }
      },
      methods: {
          triggerFormUpdateOffer() {
              this.formUpdateOffer = true 
          }, 
          setOfferFormValidity(value) {
              this.offerFormValid = value
          }, 
          updateOffer(dataToUpdate) {
            this.processing = true 
            dataToUpdate.uuid = this.offer.uuid
            this.$store.dispatch('updateOffer', dataToUpdate).then(res => {
              if(res) {
                this.$store.commit('updateActionSuccess', {
                  message: this.$t('success'), 
                  subMessage: this.$t('successfullyUpdatedOffer')
                })
                this.updateProfile(res)
              } else {
                this.$store.commit('updateActionError', {
                  message: this.$t('error'), 
                  subMessage: this.$t('somethingWentWrong')
                })
              }
            }).catch(() => {
              this.$store.commit('updateActionError', {
                message: this.$t('error'), 
                subMessage: this.$t('somethingWentWrong')
              })
            }).finally(() => {
              this.processing = false 
            })
          }
      },
      watch: {
        formUpdateOffer(value) {
          value ? this.processing = true : this.processing = false
        }
      }
  }
  </script>
    